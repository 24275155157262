import "./index.scss";
import jahag from "../../resources/images/airplane.png";
import React from "react";
import DialogBox from "../dialogBox";
import { Col, Row, Table } from "react-bootstrap";
import {
  selectIsSold,
  selectLoadingStatus,
  selectSellingStatus,
} from "../../store/features/auctionSlice";
import { useSelector } from "react-redux";

const PlayerDetails = ({ player }) => {
  let isSold = useSelector(selectIsSold);
  let sellingStatus = useSelector(selectSellingStatus);
  let loadingStatus = useSelector(selectLoadingStatus);

  let name = player?.name?.split(" ");
  let initial = name && name[0][0];
  let lastName = name?.slice(1, name.length)?.join(" ");

  return (
    <DialogBox
      header={
        <span
          style={{
            position: "relative",
            fontSize: "0.8rem",
            letterSpacing: "0.6px",
          }}
        >
          {player?.nationality &&
            !(
              player?.nationality?.toLowerCase() === "india" ||
              player?.nationality?.toLowerCase() === "indian"
            ) && (
              <img
                src={jahag}
                style={{
                  position: "absolute",
                  top: 3,
                  left: -25,
                  width: "1rem",
                  margin: "0 0.4rem 0 0",
                }}
                alt="jahaj"
              />
            )}
          {initial
            ? name[1]
              ? `${initial}. ${String(lastName)}`
              : player?.name
            : "-"}
          {/* {initial ? `${initial}. ${String(lastName)}` : "-"} */}
        </span>
      }
      headerStyle={{
        justifyContent: "left",
        textAlign: "center",
        minHeight: "1.7rem",
        margin: 0,
        // maxWidth: "19rem",
      }}
      wrapperStyle={{
        position: "relative",
        minWidth: "0",
        maxWidth: "13rem",
        width: "100%",
        minHeight: "3rem",
      }}
      style={{
        fontFamily: "Poppins-Semibold",
        margin: 0,
      }}
    >
      <Col className="col" style={{ fontSize: "0.9rem", textAlign: "center" }}>
        <Row style={{ width: "100%", justifyContent: "center", margin: 0 }}>
          <span
            style={{
              margin: "0.4rem",
              width: "9rem",
              fontSize: "0.7rem",
              letterSpacing: "0.3px",
              textTransform: "uppercase",
              fontFamily: "Poppins-Regular",
              borderBottom: "0.5px solid #9E9DD3",
            }}
          >
            {player?.role || "-"}
          </span>

          <div style={{ fontWeight: "900", fontFamily: "Poppins-SemiBold" }}>
            {`${player?.rating || 0}`}
          </div>
        </Row>
        <Row style={{ width: "100%", justifyContent: "center", margin: 0 }}>
          <Table
            size="sm"
            className="purse-table"
            style={{
              width: "95%",
              color: "#F9F8F8",
              marginBottom: "0.5rem",
            }}
          >
            <thead>
              <tr>
                <th
                  style={{
                    textAlign: "left",
                    border: "none",
                    fontFamily: "Poppins-Thin",
                    fontSize: "0.7rem",
                  }}
                >
                  ROLE
                </th>
              </tr>
            </thead>
            <tbody
              style={{
                fontSize: "0.7rem",
                textTransform: "capitalize",
                wordSpacing: "0.4px",
              }}
            >
              <tr>
                <td style={{ textAlign: "left", border: "none" }}>
                  {player?.battingHanded ? player?.battingHanded : "-"}
                </td>
              </tr>

              <tr>
                <td style={{ textAlign: "left", border: "none" }}>
                  {player?.bowlingHanded ? player?.bowlingHanded : "-"}
                </td>
              </tr>
            </tbody>
          </Table>
        </Row>
        <Row
          style={{
            backgroundColor: "#3892FE",
            width: "100%",
            margin: 0,
            letterSpacing: "0.3px",
            padding: "0.3rem 0",
            alignItems: "center",
          }}
        >
          <Col
            style={{
              padding: "0 1rem",
              fontFamily: "Poppins-Regular",
              fontSize: "0.8rem",
              textAlign: "left",
            }}
            xs="8"
          >
            BASE PRICE
          </Col>
          <Col
            style={{
              padding: 0,
              fontFamily: "Poppins-Semibold",
              fontSize: "0.9rem",
              textAlign: "center",
              //   paddingRight: "1.2rem",
            }}
            xs="4"
          >
            {player?.basePrice ? `${player?.basePrice}L` : "-"}
          </Col>
        </Row>
      </Col>
    </DialogBox>
  );
};

export default PlayerDetails;
